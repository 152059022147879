import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Pagination } from "../components/Pagination/Pagination "
import ArchiveCover from "../components/BlogCover/ArchiveCover"
import Post from "../components/Post/Post"
import ArchiveBar from "../components/ArchiveBar/ArchiveBar"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
function Archive({ data, pageContext }) {
  const posts = data.allWpPost.nodes
  // pagination data
  const { currentPage, pageCount } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === pageCount
  const prevPage = currentPage - 1 === 1 ? "" : currentPage - 1
  const nextPage = currentPage + 1
  const paginationData = {
    currentPage,
    pageCount,
    isFirst,
    isLast,
    prevPage,
    nextPage,
  }
  if (!posts.length) {
    return (
      <Layout isHomePage>
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <ApolloProvider client={client}>
      <Layout isHomePage>
        <div className="blogs">
          <ArchiveCover category={pageContext?.category} />
          <div className="container">
            <div className="blog-wrapper">
              <ArchiveBar categories={data?.allWpCategory?.nodes} />
              <div className="posts-wrapper">
                <div className="posts">
                  {posts?.map(post => {
                    return <Post post={post} />
                  })}
                </div>
                <Pagination
                  paginationData={paginationData}
                  base={pageContext.uri
                    .replace("/category", "")
                    .substring(1)
                    .slice(0, -1)}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </ApolloProvider>
  )
}

export const Head = () => (
  <Seo title="Guides Archives - Wirehouse Employer Service" />
)

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $category: String!) {
    allWpCategory {
      nodes {
        uri
        slug
        name
        count
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: $category } } } }
      }
    ) {
      nodes {
        title
        excerpt
        uri
        featuredImage {
          node {
            slug
            localFile {
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            srcSet
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            srcSet
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
  }
`
export default Archive
