import React, { useState } from "react"
import "./blogCover.scss"
import CallbackForm from "../../components/CallbackForm/CallbackForm"
function ArchiveCover({ category }) {
  const [openCallback, setOpenCallback] = useState(false)
  return (
    <div className="block-cover">
      <div className="container block-container">
        <div
          className="callback-button"
          onClick={() => {
            setOpenCallback(!openCallback)
          }}
        >
          <p>Request a callback today »</p>
        </div>
        <div className="cover-text">
          <h1>{category}</h1>
        </div>
        <CallbackForm open={openCallback} setOpen={setOpenCallback} />
      </div>
    </div>
  )
}

export default ArchiveCover
